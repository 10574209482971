"use client"

import { ApiRoutes } from "@cyna/api/index"
import { commonConfig } from "@cyna/common/config"
import { ApiErrorResponse, SessionTokenData } from "@cyna/common/constants"
import { webConfig } from "@cyna/web/config"
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation as useReactMutation,
  useQuery as useReactQuery,
} from "@tanstack/react-query"
import {
  ClientRequestOptions,
  ClientResponse,
  hc,
  InferRequestType,
  InferResponseType,
} from "hono/client"
import { ReactNode } from "react"

const { storageTokenHeader, sessionHeaderPrefix } =
  commonConfig.security.session
const saveStorageTokenFromHeader = (headers: Headers) => {
  // It's a JSON containing both the token and it's expiration date.
  // See `auth()` middleware for more details.
  const tokenData = headers.get(storageTokenHeader)

  if (!tokenData) {
    return
  }

  localStorage.setItem(storageTokenHeader, tokenData)
}

type HonoClientFunction =
  | ((
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      args: any,
      options?: ClientRequestOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<ClientResponse<any, number, "json">>)
  | ((
      // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      args?: {},
      options?: ClientRequestOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<ClientResponse<any, number, "json">>)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
})

export const apiClient = hc<ApiRoutes>(webConfig.apiBaseUrl, {
  fetch: (input: Parameters<typeof fetch>[0], requestInit?: RequestInit) => {
    const data = localStorage.getItem(storageTokenHeader) ?? ""
    const headers = (() => {
      try {
        const { token } = JSON.parse(data) as SessionTokenData

        return { Authorization: `${sessionHeaderPrefix} ${token}` }
      } catch (err) {
        // eslint-disable-next-line consistent-return, no-useless-return
        return
      }
    })()

    return fetch(input, {
      ...requestInit,
      headers: {
        ...Object.fromEntries(
          (requestInit?.headers as Headers | undefined)?.entries() ?? [],
        ),
        ...headers,
      },
      credentials: "include",
    })
  },
})

export const ApiClientProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

export const formatQueryKey = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequest extends { $get: HonoClientFunction; $url: (args?: any) => URL },
>(
  request: TRequest,
  queryArgs?: Omit<
    UndefinedInitialDataOptions<
      InferResponseType<TRequest["$get"]>,
      ApiClientError
    >,
    "queryKey" | "queryFn"
  > &
    InferRequestType<TRequest["$get"]>,
) => [request.$url().toString(), JSON.stringify(queryArgs)]

export const useQuery = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequest extends { $get: HonoClientFunction; $url: (args?: any) => URL },
>(
  request: TRequest,
  queryArgs?: Omit<
    UndefinedInitialDataOptions<
      InferResponseType<TRequest["$get"]>,
      ApiClientError
    >,
    "queryKey" | "queryFn"
  > &
    InferRequestType<TRequest["$get"]>,
) => {
  const queryResult = useReactQuery<
    Exclude<InferResponseType<TRequest["$get"]>, { error: unknown }>,
    ApiClientError
  >({
    queryKey: formatQueryKey(request, queryArgs),
    queryFn: async () => {
      const response = await request.$get(queryArgs)

      saveStorageTokenFromHeader(response.headers)

      if (response.ok) {
        return response.json() as Promise<
          Exclude<InferResponseType<TRequest["$get"]>, { error: unknown }>
        >
      }

      const error = (await response.json()) as ApiErrorResponse

      throw new ApiClientError(error.error)
    },
    placeholderData: keepPreviousData,
    ...queryArgs,
  })
  const isDone = queryResult.isLoading || queryResult.isFetching

  return {
    ...queryResult,
    isDone,
  }
}

export const useMutation = <TRequest extends HonoClientFunction>(
  request: TRequest,
  mutationArgs?: Omit<
    UseMutationOptions<
      Exclude<InferResponseType<TRequest>, { error: unknown }>,
      ApiClientError,
      InferRequestType<TRequest>
    >,
    "mutationFn" | "mutationKey"
  >,
) => {
  const { mutateAsync, ...rest } = useReactMutation({
    mutationFn: async (variables) => {
      const response = await request(variables)

      saveStorageTokenFromHeader(response.headers)

      if (response.ok) {
        return response.json() as Promise<
          Exclude<InferResponseType<TRequest>, { error: unknown }>
        >
      }

      const error = (await response.json()) as ApiErrorResponse

      throw new ApiClientError(error.error)
    },
    ...mutationArgs,
  })

  return [mutateAsync, rest] as const
}

export class ApiClientError extends Error {
  code = 0

  constructor({ message, code }: { message: string; code: number }) {
    super(message)

    this.code = code
  }
}
